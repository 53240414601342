<template>
<b-card :title="$t('permissions')">
  <b-row>
    <b-col md="12" class="my-1">
      <div class="text-right">
        <router-link :to="{ name: 'permissions.create' }" class="btn btn-sm btn-primary">{{ $t('add') }}</router-link>
      </div>
    </b-col>
  </b-row>
    <b-table responsive striped hover show-empty :items="items" :fields="fields" :current-page="currentPage" :per-page="0">
    <template v-slot:cell(actions)="row">
        <router-link :to="{ name: 'permissions.edit', params: { 'id': row.item.id } }" class="btn btn-sm btn-warning mr-1"><fa icon="pencil-alt" /></router-link>
        <b-button size="sm" class="mr-1" variant="danger" @click="removeItem(row.item.id)">
          <fa icon="trash" />
        </b-button>
    </template>
    </b-table>
    <b-pagination size="sm" :total-rows="totalItems" v-model="currentPage" :per-page="perPage"/>
</b-card>
</template>
<script>
import axios from 'axios'
export default {
    middleware: 'auth',
    layout: 'default',
    metaInfo () {
        return { title: this.$t('permissions') }
    },
    data: () => ({
      items: [],
      fields: [
        {
          key: 'title',
          label: 'Назва'
        },
        {
          key: 'name',
          label: 'Назва eng'
        },
        {
          key: 'created_at',
          label: 'Додано'
        },
        {
          key: 'actions',
          label: 'Дії'
        }
      ],
      currentPage: 1,
      perPage: 10,
      totalItems: 0
    }),
    mounted () {
        this.fetchData().catch(error => {
            console.log(error)
        })
    },
    methods: {
      async fetchData () {
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        const { data } = await axios.get(apiUrl + 'permissions?page=' + this.currentPage)
        this.items = data.data
        this.currentPage = data.meta.pagination.current_page
        this.perPage = data.meta.pagination.per_page
        this.totalItems = data.meta.pagination.total
      },
      async removeItem (id) {
        this.$root.$refs.loading.start()
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        const result = confirm(this.$t('want_to_delete'))
        if (result) {
          await axios.delete(apiUrl + 'permissions/' + id)
          this.fetchData()
          this.$root.$refs.loading.finish()
        }
      }
    },
    watch: {
        currentPage: {
            handler: function (value) {
                this.fetchData().catch(error => {
                    console.log(error)
                })
            }
        }
    }
}
</script>
